import { useUserInfo } from 'afterdoc-saas-web/shared/hooks/info/use-user-info';
import { soundReloadFlagState } from 'afterdoc-saas-web/states/sound-reload-flag-state';
import { selectedNotificationAlarmAtom } from 'afterdoc-saas-web/states/token';
import { useAtomValue } from 'jotai';
import { useCallback, useEffect, useMemo, useRef } from 'react';

export const useNotificationSound = () => {
  const { userId } = useUserInfo();

  const soundReloadFlag = useAtomValue(soundReloadFlagState);

  const audioRef = useRef<HTMLAudioElement | null>(null);
  const isChangingRef = useRef(false);

  const selectedNotificationAlarmAtomMemo = useMemo(
    () => selectedNotificationAlarmAtom(userId),
    [userId, soundReloadFlag],
  );

  const selectedNotificationAlarm = useAtomValue(selectedNotificationAlarmAtomMemo);

  const clearAudio = () => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.removeAttribute('src');
      audioRef.current.load();
      audioRef.current = null;
    }
  };

  useEffect(() => {
    isChangingRef.current = true;
    const { path, volume, usingSound } = selectedNotificationAlarm || {};

    // 사운드 사용 안 하면 기존 오디오 제거 후 종료
    if (!usingSound) {
      clearAudio();
      isChangingRef.current = false;
      return;
    }

    // 기존 오디오가 같은 소리면 볼륨만 업데이트하고 유지
    if (audioRef.current && audioRef.current.src === path) {
      if (audioRef.current.volume !== volume) {
        audioRef.current.volume = volume;
      }
      isChangingRef.current = true;
      return;
    }

    // 기존 오디오 제거 후 새 오디오 객체 생성
    clearAudio();

    const audio = new Audio(path);
    audio.volume = volume;
    audioRef.current = audio;

    isChangingRef.current = false;

    return () => {
      clearAudio();
      isChangingRef.current = false;
    };
  }, [selectedNotificationAlarm]);

  const playAudio = useCallback(() => {
    if (isChangingRef.current) {
      console.warn('설정 변경 중이므로 playAudio() 실행을 무시합니다.');
      return;
    }

    if (!audioRef.current) {
      console.warn('Audio 객체가 없어서 playAudio() 실행을 건너뜁니다.');
      return;
    }

    try {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
      audioRef.current.play().catch((error) => {
        console.error('Audio play failed:', error);
      });
    } catch (error) {
      console.error('playAudio() 실행 중 오류 발생:', error);
    }
  }, []);

  return {
    playAudio,
  };
};
