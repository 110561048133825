import '@afterdoc-design-system/public/styles/tailwind-inject.css';
import '@afterdoc-text-editor/dist/styles/merged.css';
import { SHARED_UTILS } from '@shared-utils/utils';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import FullDimmedLoadingManager from 'afterdoc-design-system/components/Atoms/Loading/FullDimmedLoading/FullDimmedLoadingManager';
import ToastManager from 'afterdoc-design-system/components/Atoms/Toast/ToastManager';
import AlertManager from 'afterdoc-design-system/components/Molecules/Alert/AlertManager';
import ContextMenuManager from 'afterdoc-design-system/components/Molecules/ContextMenu/ContextMenuManager';
import DialogManager from 'afterdoc-design-system/components/Molecules/Dialog/DialogManager';
import ModalManager from 'afterdoc-design-system/components/Molecules/Modal/ModalManager';
import OverlayPageManager from 'afterdoc-design-system/components/Molecules/OverlayPage/OverlayPageManager';
import ProgressBarManager from 'afterdoc-design-system/components/Molecules/ProgressBar/ProgressBarManager';
import App from 'afterdoc-saas-web/App';
import { apiClient } from 'afterdoc-saas-web/apis/instances/api-client';
import type {
  ApiUserElFindOneParams,
  HospitalsBackgroundJobsHandlerParams,
  User,
} from 'afterdoc-saas-web/apis/swaggers/swagger-docs';
import { isCustomerManagementSaveLockState } from 'afterdoc-saas-web/hooks/push-notification/states/is-customer-management-save-lock';
import { usePatientsBatchProcessingNotification } from 'afterdoc-saas-web/hooks/push-notification/use-patients-batch-processing-notification';
import { usePushNotificationObserver } from 'afterdoc-saas-web/hooks/push-notification/use-push-notification-observer';
import { useResetTableOnSaveCustomerManagementTable } from 'afterdoc-saas-web/hooks/push-notification/use-reset-table-on-save-customer-management-table';
import { useResetTableOnTemporarySaveCustomerManagementTable } from 'afterdoc-saas-web/hooks/push-notification/use-reset-table-on-temporary-save-customer-management-table';
import { useAuthRevalidation } from 'afterdoc-saas-web/hooks/use-auth-revalidation';
import { useGlobalAnchorClick } from 'afterdoc-saas-web/hooks/use-global-anchor-click';
import ErrorBoundary from 'afterdoc-saas-web/shared/components/ErrorBoundary/ErrorBoundary';
import FullLoading from 'afterdoc-saas-web/shared/components/FullLoading/FullLoading';
import {
  LOCAL_HOSPITAL_ID,
  LOCAL_TOKEN,
  LOCAL_USER_ID,
} from 'afterdoc-saas-web/shared/constants/temp-id';
import {
  selectedHospitalIDAtom,
  tokenAtom,
  userIDAtom,
  userInfoAtom,
  userTypeAtom,
} from 'afterdoc-saas-web/states/token';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import calendar from 'dayjs/plugin/calendar';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isoWeek from 'dayjs/plugin/isoWeek';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import { useAtom, useSetAtom } from 'jotai';
import { DevTools as JotaiDevTools } from 'jotai-devtools';
import 'jotai-devtools/styles.css';
import { Suspense, useEffect, useState } from 'react';
import 'react-day-picker/dist/style.css';
import 'react-image-gallery/styles/css/image-gallery.css';

dayjs.locale('ko');
dayjs.extend(relativeTime);
dayjs.extend(calendar);
dayjs.extend(advancedFormat);
dayjs.extend(customParseFormat);
dayjs.extend(utc);
dayjs.extend(isoWeek);
dayjs.extend(weekOfYear);

const fetchUserInfo = async (params: ApiUserElFindOneParams) => {
  const response = await apiClient.v2.apiUserElFindOne(params);
  return SHARED_UTILS.api.checkApiResponse(response.data) as User & {
    _id: string;
  };
};

const fetchIsCustomerManagementSaveLock = async (params: HospitalsBackgroundJobsHandlerParams) => {
  const response = await apiClient.v3.hospitalsBackgroundJobsHandler(params);
  return response.data;
};

// 20초 (기본 5분)
const DEFAULT_GC_TIME = 1000 * 60 * 5;
// 10초 (기본 5초)
const DEFAULT_STALE_TIME = 1000 * 60 * 5;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: DEFAULT_STALE_TIME,
      gcTime: DEFAULT_GC_TIME,
    },
  },
});

const AppContainer = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <InnerApp />
    </QueryClientProvider>
  );
};

export type UserInfo = User & {
  token: string;
  type: string;
  _id: string;
};

const InnerApp = () => {
  const [isElectronEnabled, setIsElectronEnabled] = useState<boolean | null>(null);

  const [token, setToken] = useAtom(tokenAtom);
  const setIsCustomerManagementSaveLock = useSetAtom(isCustomerManagementSaveLockState);

  const setUserType = useSetAtom(userTypeAtom);
  const setUserID = useSetAtom(userIDAtom);
  const setUserInfo = useSetAtom(userInfoAtom);
  const setSelectedHospitalID = useSetAtom(selectedHospitalIDAtom);
  const [isLoading, setIsLoading] = useState(true);

  // 푸시 알림 핸들링
  usePushNotificationObserver();
  // 재로그인 체크 및 로직을 위한 커스텀 훅
  useAuthRevalidation();
  // a 태그 글로벌 핸들링
  useGlobalAnchorClick();
  // 찐저장 배치 작업 알림 핸들링
  usePatientsBatchProcessingNotification();
  // 고객정보 수정 테이블 리셋 알림 핸들링
  useResetTableOnSaveCustomerManagementTable();
  // 고객정보 수정 임시저장 테이블 리셋 알림 핸들링
  useResetTableOnTemporarySaveCustomerManagementTable();

  // 테스트 용도를 위해 로컬 환경에서 사용되는 로직
  useEffect(() => {
    if (window?.electron === undefined) {
      setIsElectronEnabled(false);
      setToken(`Bearer ${LOCAL_TOKEN}`);

      const loadUserInfoFromDev = async () => {
        const response = await fetchUserInfo({ userID: LOCAL_USER_ID });
        const devUserInfo = response as User & {
          _id: string;
        };

        const userType = devUserInfo.type as number;
        const userID = devUserInfo._id;

        setUserType(userType);
        setUserID(userID);
        setUserInfo(devUserInfo);
        setSelectedHospitalID(LOCAL_HOSPITAL_ID);
      };

      loadUserInfoFromDev();
      setTimeout(() => {
        setIsLoading(false);
      }, 0);
    }
  }, []);

  // 실제 Electron 앱에서 로그인 정보를 받아오는 로직
  useEffect(() => {
    if (window?.electron) {
      const loadUserInfoFromElectron = async (
        _: Record<string, never>,
        payload: Payload<UserInfo>,
      ) => {
        const payloadUserInfo = payload.value;
        const token = payloadUserInfo.token;

        setToken(`Bearer ${token}`);
        setUserType(payloadUserInfo.type);
        setUserID(payloadUserInfo._id);
        setUserInfo(payloadUserInfo);
        setSelectedHospitalID(payloadUserInfo.hospitalID?.[0]);

        const isCustomerManagementSaveLock = await fetchIsCustomerManagementSaveLock({
          hospitalID: payloadUserInfo.hospitalID?.[0],
        });

        // 찐저장 배치 작업이 진행중인 경우 고객입력 기능 잠금
        if (isCustomerManagementSaveLock?.data?.[0]) {
          setIsCustomerManagementSaveLock({
            isLock: true,
            count: isCustomerManagementSaveLock?.data[0].totalJobs ?? 0,
          });
        }

        window.electron?.ipcRenderer.send(
          'Application.Set.hospitalID',
          payloadUserInfo.hospitalID?.[0],
        );
        setTimeout(() => {
          setIsLoading(false);
        }, 0);
      };

      window.electron?.ipcRenderer.on('Application.Get.userInfo', loadUserInfoFromElectron);
      window.electron?.ipcRenderer.send('Application.Get.userInfo');

      return () => {
        window.electron?.ipcRenderer.removeAllListeners('Application.Get.userInfo');
      };
    }
  }, []);

  useEffect(() => {
    if (window.electron) {
      setIsElectronEnabled(true);
    }
  }, []);

  if (isLoading || !token.length) {
    return <FullLoading />;
  }

  return (
    <>
      {import.meta.env.VITE_ENABLE_DEBUG_TOOLS_ONLY_DEV === 'true' && (
        <>
          <JotaiDevTools isInitialOpen={false} position='top-left' />
          <ReactQueryDevtools initialIsOpen={false} buttonPosition='top-right' position='right' />
        </>
      )}
      <ErrorBoundary>
        <Suspense fallback={<FullLoading />}>
          <App isElectronEnabled={isElectronEnabled} />
        </Suspense>
      </ErrorBoundary>
      <ToastManager />
      <DialogManager />
      <ProgressBarManager />
      <OverlayPageManager />
      <ModalManager />
      <AlertManager />
      <ContextMenuManager />
      <FullDimmedLoadingManager />
    </>
  );
};

export default AppContainer;
