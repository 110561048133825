import { useQueryClient } from '@tanstack/react-query';
import { QUERY_KEY } from 'afterdoc-saas-web/apis/swaggers/query-key';
import { shouldResetCustomerManagementTableOnTemporarySaveState } from 'afterdoc-saas-web/hooks/push-notification/states/should-reset-customer-management-table-request-on-temporary-save';
import { useSelectedHospitalInfo } from 'afterdoc-saas-web/shared/hooks/info/use-selected-hospital-info';
import { useUserInfo } from 'afterdoc-saas-web/shared/hooks/info/use-user-info';
import type { DataNotificationPayload } from 'hooks/use-data-event-bus-handler/event-bus';
import { useDataEventBusHandler } from 'hooks/use-data-event-bus-handler/use-data-event-bus-handler';
import { useSetAtom } from 'jotai';
import { useEffect, useMemo, useRef } from 'react';

export const useResetTableOnTemporarySaveCustomerManagementTable = () => {
  const queryClient = useQueryClient();
  const { userId: loggedInUserID } = useUserInfo();

  const handleTemporaryPatientsNotificationRef = useRef<
    ((payload: DataNotificationPayload) => void) | null
  >(null);

  const notificationConfigs = useMemo(
    () => [
      {
        codes: ['d_TemporaryPatientsUpdate_el'],
        handler: (payload: DataNotificationPayload) =>
          handleTemporaryPatientsNotificationRef.current?.(payload),
      },
    ],
    [],
  );

  const { hospitalID } = useSelectedHospitalInfo();
  const setShouldResetCustomerManagementTableOnTemporarySave = useSetAtom(
    shouldResetCustomerManagementTableOnTemporarySaveState,
  );

  useDataEventBusHandler(notificationConfigs);

  useEffect(() => {
    handleTemporaryPatientsNotificationRef.current = async (payload: DataNotificationPayload) => {
      const { hospitalID: messageHospitalID, senderID } = payload.data;

      if (hospitalID === messageHospitalID && senderID !== loggedInUserID) {
        setShouldResetCustomerManagementTableOnTemporarySave(true);

        await Promise.all([
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEY.temporaryPatientsIsLockHandler, { hospitalID }],
          }),
        ]);
      } else {
        setShouldResetCustomerManagementTableOnTemporarySave(false);
      }
    };
  }, []);
};
