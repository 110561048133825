import {
  Automation,
  AutomationActivated,
  Calendar,
  CalendarActivated,
  Chat,
  ChatActivated,
  Contents,
  ContentsActivated,
  Db,
  DbActivated,
  Manual,
  ManualActivated,
  Setting,
  SettingActivated,
  Users,
  UsersActivated,
} from 'afterdoc-saas-web/assets/icons/gnb';
import type { ReactNode } from 'react';

export const ROUTES_PATH = {
  HOME: '/',
  ERROR: '/error',
  ADMIN_CHAT: '/admin-chat',
  CUSTOMER_CHAT: '/customer-chat',
  CUSTOMER_MANAGEMENT: '/customer-management',
  RESERVATION_MANAGEMENT: '/reservation-management',
  AUTOMATION: '/automation',
  CONTENT: '/content',
  HOSPITAL_MANUAL: '/hospital-manual',
  HOSPITAL_SETTING: '/hospital-setting',
  USER_INFO_SETTINGS: '/user-info-settings',
  INSTAGRAM_LINK_RESULT: '/instagram-link-result',
  TAEWOONG_TEST: '/taewoong-test',
  JUNSU_TEST: '/junsu-test',
  TAESUNG_TEST: '/taesung-test',
  ERROR_TEST: '/error-test',
} as const;

export type MenuItemType = {
  nonActiveIcon: ReactNode;
  activeIcon: ReactNode;
  title: string;
  hasLine: boolean;
  key: (typeof ROUTES_PATH)[keyof typeof ROUTES_PATH];
  isLocal?: boolean;
};

export const MENU_ITEMS: MenuItemType[] = [
  {
    nonActiveIcon: <Chat width={24} height={24} />,
    activeIcon: <ChatActivated width={24} height={24} />,
    title: '고객채팅',
    hasLine: false,
    key: ROUTES_PATH.CUSTOMER_CHAT,
  },
  {
    nonActiveIcon: <Db width={24} height={24} />,
    activeIcon: <DbActivated width={24} height={24} />,
    title: '고객입력',
    hasLine: false,
    key: ROUTES_PATH.CUSTOMER_MANAGEMENT,
  },
  {
    nonActiveIcon: <Automation width={24} height={24} />,
    activeIcon: <AutomationActivated width={24} height={24} />,
    title: '자동화',
    hasLine: false,
    key: ROUTES_PATH.AUTOMATION,
  },
  {
    nonActiveIcon: <Manual width={24} height={24} />,
    activeIcon: <ManualActivated width={24} height={24} />,
    title: '원내매뉴얼',
    hasLine: false,
    key: ROUTES_PATH.HOSPITAL_MANUAL,
  },
  {
    nonActiveIcon: <Contents width={24} height={24} />,
    activeIcon: <ContentsActivated width={24} height={24} />,
    title: '콘텐츠',
    hasLine: true,
    key: ROUTES_PATH.CONTENT,
  },
  {
    nonActiveIcon: <Calendar width={24} height={24} />,
    activeIcon: <CalendarActivated width={24} height={24} />,
    title: '예약관리',
    hasLine: false,
    key: ROUTES_PATH.RESERVATION_MANAGEMENT,
  },
  {
    nonActiveIcon: <Setting width={24} height={24} />,
    activeIcon: <SettingActivated width={24} height={24} />,
    title: '태웅테스트',
    hasLine: false,
    key: ROUTES_PATH.TAEWOONG_TEST,
    isLocal: true,
  },
  {
    nonActiveIcon: <Setting width={24} height={24} />,
    activeIcon: <SettingActivated width={24} height={24} />,
    title: '준수테스트',
    hasLine: false,
    key: ROUTES_PATH.JUNSU_TEST,
    isLocal: true,
  },
  {
    nonActiveIcon: <Setting width={24} height={24} />,
    activeIcon: <SettingActivated width={24} height={24} />,
    title: '태성테스트',
    hasLine: false,
    key: ROUTES_PATH.TAESUNG_TEST,
    isLocal: true,
  },
  {
    nonActiveIcon: <Setting width={24} height={24} />,
    activeIcon: <SettingActivated width={24} height={24} />,
    title: '에러테스트',
    hasLine: false,
    key: ROUTES_PATH.ERROR_TEST,
    isLocal: true,
  },
  {
    nonActiveIcon: <Users width={24} height={24} />,
    activeIcon: <UsersActivated width={24} height={24} />,
    title: '직원채팅',
    hasLine: false,
    key: ROUTES_PATH.ADMIN_CHAT,
  },
  {
    nonActiveIcon: <Setting width={24} height={24} />,
    activeIcon: <SettingActivated width={24} height={24} />,
    title: '병원설정',
    hasLine: false,
    key: ROUTES_PATH.HOSPITAL_SETTING,
  },
] as const;
