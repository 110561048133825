import { isCustomerManagementSaveLockState } from 'afterdoc-saas-web/hooks/push-notification/states/is-customer-management-save-lock';
import { useSelectedHospitalInfo } from 'afterdoc-saas-web/shared/hooks/info/use-selected-hospital-info';
import { useUserInfo } from 'afterdoc-saas-web/shared/hooks/info/use-user-info';
import type { DataNotificationPayload } from 'hooks/use-data-event-bus-handler/event-bus';
import { useDataEventBusHandler } from 'hooks/use-data-event-bus-handler/use-data-event-bus-handler';
import { useSetAtom } from 'jotai';
import { useEffect, useMemo, useRef } from 'react';

export const usePatientsBatchProcessingNotification = () => {
  const handlePatientsBatchProcessingNotificationRef = useRef<
    ((payload: DataNotificationPayload) => void) | null
  >(null);

  const { hospitalID } = useSelectedHospitalInfo();
  const { userId: loggedInUserID } = useUserInfo();

  const notificationConfigs = useMemo(
    () => [
      {
        codes: ['d_PatientsBatchProcessing_el'],
        handler: (payload: DataNotificationPayload) =>
          handlePatientsBatchProcessingNotificationRef.current?.(payload),
      },
    ],
    [],
  );
  const setIsCustomerManagementSaveLock = useSetAtom(isCustomerManagementSaveLockState);

  useDataEventBusHandler(notificationConfigs);

  useEffect(() => {
    handlePatientsBatchProcessingNotificationRef.current = async (
      payload: DataNotificationPayload,
    ) => {
      if (payload.data.senderID === loggedInUserID) {
        return;
      }

      setIsCustomerManagementSaveLock({
        isLock: true,
        count: payload.data.totalJobs as number,
      });
    };
  }, [hospitalID]);
};
