import { apiClient } from '@apis/instances/api-client';
import { QUERY_KEY } from '@apis/swaggers/query-key';
import type {
  ElectronNotificationSoundsHandlerParams,
  ElectronNotificationSoundsHandlerResponse,
} from '@apis/swaggers/swagger-docs';
import { useQuery } from '@tanstack/react-query';
import AlarmVolumeRadioGroup from '@templates/AlarmSoundSettingDialog/components/AlarmVolumeRadioGroup';
import ContainedButton from 'afterdoc-design-system/components/Atoms/Button/ContainedButton';
import Checkbox from 'afterdoc-design-system/components/Atoms/Checkbox/Checkbox';
import { toastService } from 'afterdoc-design-system/components/Atoms/Toast/Toast.service';
import Icon from 'afterdoc-design-system/components/Foundations/Icon/Icon';
import { dialogService } from 'afterdoc-design-system/components/Molecules/Dialog/Dialog.service';
import TextFieldSelectBoxDropdown from 'afterdoc-design-system/components/Organisms/Dropdown/TextFieldSelectBoxDropdown/TextFieldSelectBoxDropdown';
import { useSelectedHospitalInfo } from 'afterdoc-saas-web/shared/hooks/info/use-selected-hospital-info';
import { useUserInfo } from 'afterdoc-saas-web/shared/hooks/info/use-user-info';
import { soundReloadFlagState } from 'afterdoc-saas-web/states/sound-reload-flag-state';
import { selectedNotificationAlarmAtom } from 'afterdoc-saas-web/states/token';
import dayjs from 'dayjs';
import { useSetAtom } from 'jotai';
import { useAtom } from 'jotai/index';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { SHARED_UTILS } from 'utils/utils';

type ResSoundList = ElectronNotificationSoundsHandlerResponse['data'];

const fetchNotificationSound = async (params: ElectronNotificationSoundsHandlerParams) => {
  const response = await apiClient.v3.electronNotificationSoundsHandler(params);
  return SHARED_UTILS.api.checkApiResponse<ResSoundList>(response.data);
};

export default function AlarmSoundSettingDialog() {
  const { userId } = useUserInfo();
  const { hospitalID } = useSelectedHospitalInfo();

  const selectedNotificationAlarmAtomMemo = useMemo(
    () => selectedNotificationAlarmAtom(userId),
    [userId],
  );
  const [selectedNotificationAlarm, setSelectedNotificationAlarm] = useAtom(
    selectedNotificationAlarmAtomMemo,
  );

  const setSoundReloadFlag = useSetAtom(soundReloadFlagState);

  const [soundList, setSoundList] = useState<ResSoundList>([]);
  const [selectedIndex, setSelectedIndex] = useState<number | undefined>();
  const [usingSound, setUsingSound] = useState<boolean>(true);
  const [volume, setVolume] = useState(0.6);
  const initialRender = useRef(false);

  const { data: fetchedSounds } = useQuery({
    queryKey: [QUERY_KEY.electronNotificationSoundsHandler, { hospitalID: hospitalID }] as const,
    queryFn: ({ queryKey }) => fetchNotificationSound(queryKey[1]),
  });

  const currentSelectedItem = useMemo(() => {
    if (selectedIndex === undefined || !fetchedSounds) return null;
    return fetchedSounds[selectedIndex];
  }, [selectedIndex, fetchedSounds]);

  const isModified = useMemo(() => {
    if (!selectedNotificationAlarm || !currentSelectedItem) return false;

    return (
      selectedNotificationAlarm.usingSound !== usingSound ||
      selectedNotificationAlarm.soundID !== currentSelectedItem._id ||
      selectedNotificationAlarm.path !== currentSelectedItem.path ||
      selectedNotificationAlarm.volume !== volume
    );
  }, [fetchedSounds, selectedNotificationAlarm, usingSound, selectedIndex, volume]);

  const playPreview = () => {
    if (!currentSelectedItem || !currentSelectedItem.path) return;
    const audio = new Audio(currentSelectedItem.path);
    audio.volume = volume;
    audio.play();
  };

  const handleVolumeChange = useCallback((volume: number) => {
    setVolume(volume);
  }, []);

  const handleChange = () => {
    if (!currentSelectedItem) {
      toastService.errorMsg({ text: '변경을 실패했습니다.' });
      return;
    }

    const { _id: soundID, path } = currentSelectedItem;

    if (soundID && path) {
      setSelectedNotificationAlarm({ usingSound, soundID, path, volume });
      toastService.successMsg({ text: '알림 설정을 변경했습니다.' });
      setSoundReloadFlag(dayjs().toISOString());
    } else {
      toastService.errorMsg({ text: '변경을 실패했습니다.' });
    }
  };

  const closeCurrentDialog = () => {
    if (isModified) {
      toastService.errorMsg({ text: '변경을 취소했습니다.' });
    }
    dialogService.popById('alarm-sound-setting-dialog');
  };

  useEffect(() => {
    if (fetchedSounds) {
      setSoundList(fetchedSounds);

      if (!initialRender.current && selectedNotificationAlarm) {
        initialRender.current = true;

        const index = fetchedSounds.findIndex(
          (sound) => sound._id === selectedNotificationAlarm.soundID,
        );
        if (index !== -1) setSelectedIndex(index);

        setUsingSound(selectedNotificationAlarm.usingSound);
        setVolume(selectedNotificationAlarm.volume);
      }
    }
  }, [fetchedSounds, selectedNotificationAlarm]);

  return (
    <div className='flex h-[280px] flex-col px-20 pt-10 pb-20'>
      <div className='flex flex-col gap-4'>
        {/* 알림 소리 사용 설정 */}
        <Checkbox
          label={<span className='text-Header12 text-black700'>알림 소리 사용</span>}
          checked={usingSound}
          onChange={() => setUsingSound(!usingSound)}
        />

        {/* 사운드 선택 */}
        <div className='flex-center gap-10 py-4'>
          <TextFieldSelectBoxDropdown
            options={soundList.map((sound) => sound.name)}
            wrapperClassName={'flex-grow'}
            onSelect={setSelectedIndex}
            selectedIndex={selectedIndex}
            defaultValue='선택'
          />
          <div className='flex-center cursor-pointer gap-4' onClick={playPreview}>
            <Icon name={'volume-high'} color={'blue500'} size={20} />
            <span className={'text-Body12 text-black200'}>미리듣기</span>
          </div>
        </div>
      </div>

      {/* 볼륨 조절 */}
      <div className='mt-20 flex flex-col gap-4'>
        <span className='text-Header12 text-black700'>소리 크기</span>
        <AlarmVolumeRadioGroup volume={volume} onHandleChange={handleVolumeChange} />
      </div>

      <div className='mx-auto mt-auto flex gap-10'>
        <ContainedButton btnColor={'secondary'} onClick={closeCurrentDialog}>
          취소
        </ContainedButton>
        <ContainedButton disabled={!isModified} onClick={handleChange}>
          변경
        </ContainedButton>
      </div>
    </div>
  );
}
