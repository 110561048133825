import * as Sentry from '@sentry/react';
import ErrorPage from 'afterdoc-saas-web/pages/ErrorPage';
import { Component, type ErrorInfo, type ReactNode } from 'react';

interface Props {
  children: ReactNode;
  message?: string;
  fallback?: ReactNode;
}

interface State {
  hasError: boolean;
  errorInfo: string | null;
  errorType: 'NetworkError' | 'TypeError' | 'UnknownError' | null;
}

class ErrorBoundary extends Component<Props, State> {
  state: State = {
    hasError: false,
    errorInfo: null,
    errorType: null,
  };

  static getDerivedStateFromError(): State {
    return { hasError: true, errorInfo: null, errorType: null };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    let errorType: 'NetworkError' | 'TypeError' | 'UnknownError' = 'UnknownError';

    // 에러 타입을 구분하는 로직
    if (error instanceof TypeError) {
      errorType = 'TypeError';
    } else if (
      error.message.includes('Network Error') ||
      error.message.includes('Failed to fetch')
    ) {
      errorType = 'NetworkError';
    }

    const errorDetails = {
      error: error.message,
      stack: error.stack,
      componentStack: errorInfo.componentStack,
      timestamp: new Date().toISOString(),
    };

    this.setState({
      errorInfo: JSON.stringify(errorDetails, null, 2),
      errorType,
    });

    // Sentry에 에러 정보 전송
    Sentry.captureException(error, {
      extra: {
        ...errorDetails,
        errorType,
      },
      tags: {
        errorType,
      },
    });

    console.error('Error caught by ErrorBoundary:', errorDetails);
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.children !== prevProps.children) {
      this.setState({ hasError: false, errorInfo: null, errorType: null });
    }
  }

  render() {
    const { hasError, errorInfo, errorType } = this.state;
    const { fallback } = this.props;

    if (hasError) {
      if (fallback) {
        return fallback;
      }

      return <ErrorPage errorInfo={errorInfo} errorType={errorType} />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
