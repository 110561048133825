import { apiClient } from '@apis/instances/api-client';
import { Client_Unauthorized } from '@apis/instances/axios-instance';
import type { DataNotificationPayload } from '@shared-hooks/use-data-event-bus-handler/event-bus';
import { useMutation } from '@tanstack/react-query';
import { modalService } from 'afterdoc-design-system/components/Molecules/Modal/Modal.service';
import { useUserInfo } from 'afterdoc-saas-web/shared/hooks/info/use-user-info';
import { authStatusAtom } from 'afterdoc-saas-web/states/token';
import { useDataEventBusHandler } from 'hooks/use-data-event-bus-handler/use-data-event-bus-handler';
import { useAtom } from 'jotai/index';
import { useEffect, useMemo, useRef } from 'react';

export const logoutPushCodes = {
  d_UserPasswordUpdate_el: 'd_UserPasswordUpdate_el',
  d_UserRemove_el: 'd_UserRemove_el',
  d_Logout_el: 'd_Logout_el',
} as const;

const logoutMessages: Record<string, string> = {
  [logoutPushCodes.d_UserPasswordUpdate_el]: '로그인 정보가 변경되어 로그아웃되었습니다.',
  [logoutPushCodes.d_UserRemove_el]: '계정이 삭제되어 로그아웃되었습니다.',
  [logoutPushCodes.d_Logout_el]: '다른 PC에서 로그인하여 로그아웃 되었습니다.',

  [Client_Unauthorized]: '세션이 만료되어 로그아웃 되었습니다.',
};

export const useAuthRevalidation = () => {
  const [authStatus, setAuthStatus] = useAtom(authStatusAtom);
  const { userId: loggedInUserID } = useUserInfo();

  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const isInitialized = useRef(true);

  const handleNotificationRef = useRef<((payload: DataNotificationPayload) => void) | null>(null);

  const logoutMutation = useMutation({ mutationFn: () => apiClient.v3.elLogoutHandler({}) });

  const showReLoginPopup = (code: string) => {
    const content = logoutMessages[code];

    modalService.defaultWarning({
      id: 'auth-logout-due-to-change',
      title: '로그아웃 되었습니다.',
      contents: content,
      buttonText: '확인',
      buttonType: 'CONFIRM',
      onConfirm: async () => {
        setAuthStatus(null);
        logoutMutation.mutate();
        setTimeout(() => {
          window.electron?.ipcRenderer.send('Application.logout');
        }, 200);
      },
    });
  };

  const notificationConfigs = useMemo(
    () => [
      {
        codes: [
          logoutPushCodes.d_UserPasswordUpdate_el,
          logoutPushCodes.d_UserRemove_el,
          logoutPushCodes.d_Logout_el,
        ],
        handler: (payload: DataNotificationPayload) => handleNotificationRef.current?.(payload),
      },
    ],
    [],
  );

  useDataEventBusHandler(notificationConfigs);

  useEffect(() => {
    handleNotificationRef.current = (payload: DataNotificationPayload) => {
      const { code, senderID, userID } = payload.data;
      switch (code) {
        case logoutPushCodes.d_UserPasswordUpdate_el:
        case logoutPushCodes.d_UserRemove_el: {
          if (senderID === loggedInUserID && userID === loggedInUserID) return;
          break;
        }
      }
      setAuthStatus({ code, requiresReLogin: true });
    };
  }, [loggedInUserID]);

  useEffect(() => {
    const entries = performance.getEntriesByType('navigation')[0];
    const entriesNavigationTiming = entries as PerformanceNavigationTiming;
    const isReload = entriesNavigationTiming?.type === 'reload';

    if (isInitialized.current) {
      isInitialized.current = false;

      if (!isReload) {
        setAuthStatus(null);
        return;
      }
    }

    if (authStatus?.requiresReLogin) {
      timeoutRef.current = setTimeout(() => {
        showReLoginPopup(authStatus.code);
      }, 500);
    }

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
      }
    };
  }, [authStatus]);
};
