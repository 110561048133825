import { createDataPushNotificationQuery } from 'afterdoc-saas-web/apis/push/data-push/create-data-push-notification-query';
import type { DataPushNotificationQuery } from 'afterdoc-saas-web/apis/push/data-push/data-push-type';
import { QUERY_KEY } from 'afterdoc-saas-web/apis/swaggers/query-key';

export const DATA_PUSH_KEY = {
  // d_ServiceNotice_h: 'd_ServiceNotice_h',
  d_UserAuthorizationUpdate_el: 'd_UserAuthorizationUpdate_el',

  d_ChatroomUpdate_el: 'd_ChatroomUpdate_el',

  d_ChatReceive_h: 'd_ChatReceive_h',
  // d_ChatReceive_an: 'd_ChatReceive_an', //서비스 매니저가 보낸 채팅 메시지 수신

  d_HServiceUpdate_el: 'd_HServiceUpdate_el',
  d_HServiceSuspend_el: 'd_HServiceSuspend_el',

  d_UserUpdate_el: 'd_UserUpdate_el',

  d_HospitalUpdate_el: 'd_HospitalUpdate_el',
  d_HServiceMemoUpdate_el: 'd_HServiceMemoUpdate_el',

  d_ServiceSettingUpdate_el: 'd_ServiceSettingUpdate_el',
} as const;

export interface DataPushNotificationDataMap {
  [DATA_PUSH_KEY.d_UserAuthorizationUpdate_el]: {};

  [DATA_PUSH_KEY.d_ChatroomUpdate_el]: {};

  [DATA_PUSH_KEY.d_ChatReceive_h]: {};
  // [DATA_PUSH_KEY.d_ChatReceive_an]: {};

  [DATA_PUSH_KEY.d_HServiceMemoUpdate_el]: {};
  [DATA_PUSH_KEY.d_HospitalUpdate_el]: {};

  [DATA_PUSH_KEY.d_HServiceUpdate_el]: {};
  [DATA_PUSH_KEY.d_HServiceSuspend_el]: {};

  [DATA_PUSH_KEY.d_UserUpdate_el]: {};

  // [DATA_PUSH_KEY.d_ServiceNotice_h]: {};

  [DATA_PUSH_KEY.d_ServiceSettingUpdate_el]: {};
}

export const DataPushNotification: {
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  [K in keyof typeof DATA_PUSH_KEY]: DataPushNotificationQuery<any, K>[];
} = {
  [DATA_PUSH_KEY.d_UserAuthorizationUpdate_el]: [
    createDataPushNotificationQuery({
      queryKey: QUERY_KEY.v2apiUserElFindOne,
      getQueryKey: () => [QUERY_KEY.v2apiUserElFindOne],
    }),
  ],

  ///////////////////////////
  //채팅 메시지 수신 -> 채팅리스트 갱신
  [DATA_PUSH_KEY.d_ChatReceive_h]: [
    createDataPushNotificationQuery({
      queryKey: QUERY_KEY.apiChatroomElFind,
      getQueryKey: () => [QUERY_KEY.apiChatroomElFind],
    }),
    createDataPushNotificationQuery({
      queryKey: QUERY_KEY.apiChatroomElCount,
      getQueryKey: () => [QUERY_KEY.apiChatroomElCount],
    }),
  ],

  ///////////////////////////

  //d_ChatroomUpdate_el - 채팅방 정보가 변경되었을 때
  [DATA_PUSH_KEY.d_ChatroomUpdate_el]: [
    createDataPushNotificationQuery({
      queryKey: QUERY_KEY.apiChatroomElFind,
      getQueryKey: () => [QUERY_KEY.apiChatroomElFind],
    }),
    createDataPushNotificationQuery({
      queryKey: QUERY_KEY.apiPatientsElDetail,
      getQueryKey: () => [QUERY_KEY.apiPatientsElDetail],
    }),
  ],
  //(직원채팅)d_ChatroomUpdateDoctor_el

  //병원 정보가 변경되었을 때
  [DATA_PUSH_KEY.d_HospitalUpdate_el]: [
    createDataPushNotificationQuery({
      queryKey: QUERY_KEY.apiChatroomElFind,
      getQueryKey: () => [QUERY_KEY.apiChatroomElFind],
    }),
    createDataPushNotificationQuery({
      queryKey: QUERY_KEY.apiChatElFindId,
      getQueryKey: () => [QUERY_KEY.apiChatElFindId],
    }),
    createDataPushNotificationQuery({
      queryKey: QUERY_KEY.apiHospitalsElFind,
      getQueryKey: () => [QUERY_KEY.apiHospitalsElFind],
    }),
  ],

  //환자 메모 정보가 변경되었을 때
  [DATA_PUSH_KEY.d_HServiceMemoUpdate_el]: [
    createDataPushNotificationQuery({
      queryKey: QUERY_KEY.apiPatientsMemosElFindOne,
      getQueryKey: () => [QUERY_KEY.apiPatientsMemosElFindOne],
    }),
  ],

  //고객 정보가 변경되었을 때
  [DATA_PUSH_KEY.d_HServiceUpdate_el]: [
    createDataPushNotificationQuery({
      queryKey: QUERY_KEY.apiChatroomElFind,
      getQueryKey: () => [QUERY_KEY.apiChatroomElFind],
    }),
    createDataPushNotificationQuery({
      queryKey: QUERY_KEY.apiChatroomElCount,
      getQueryKey: () => [QUERY_KEY.apiChatroomElCount],
    }),
    createDataPushNotificationQuery({
      queryKey: QUERY_KEY.apiPatientsElDetail,
      getQueryKey: () => [QUERY_KEY.apiPatientsElDetail],
    }),
    createDataPushNotificationQuery({
      queryKey: QUERY_KEY.apiPatientsElListOrSearch,
      getQueryKey: () => [QUERY_KEY.apiPatientsElListOrSearch],
    }),
  ],

  //고객을 이용중단 했을 때
  [DATA_PUSH_KEY.d_HServiceSuspend_el]: [
    createDataPushNotificationQuery({
      queryKey: QUERY_KEY.apiChatroomElFind,
      getQueryKey: () => [QUERY_KEY.apiChatroomElFind],
    }),
    createDataPushNotificationQuery({
      queryKey: QUERY_KEY.apiChatroomElCount,
      getQueryKey: () => [QUERY_KEY.apiChatroomElCount],
    }),
    createDataPushNotificationQuery({
      queryKey: QUERY_KEY.apiChatroomElFindOne,
      getQueryKey: () => [QUERY_KEY.apiChatroomElFindOne],
    }),
  ],

  //유저 정보가 변경되었을 때
  [DATA_PUSH_KEY.d_UserUpdate_el]: [
    createDataPushNotificationQuery({
      queryKey: QUERY_KEY.apiChatroomElFind,
      getQueryKey: () => [QUERY_KEY.apiChatroomElFind],
    }),
    createDataPushNotificationQuery({
      queryKey: QUERY_KEY.apiChatElFindId,
      getQueryKey: () => [QUERY_KEY.apiChatElFindId],
    }),
    createDataPushNotificationQuery({
      queryKey: QUERY_KEY.v2apiUserElFindOne,
      getQueryKey: () => [QUERY_KEY.v2apiUserElFindOne],
    }),
  ],

  [DATA_PUSH_KEY.d_ServiceSettingUpdate_el]: [
    createDataPushNotificationQuery({
      queryKey: QUERY_KEY.apiPatientsElTemporaryListOrSearch,
      getQueryKey: () => [QUERY_KEY.apiServiceSettingsElFindOne],
    }),
  ],
};
