import { ROUTES_PATH } from '@shared/components/NavigationBar/constants/menu-items';
import { showModalBeforeRouteState } from '@shared/states/show-modal-before-route';
import { dialogService } from 'afterdoc-design-system/components/Molecules/Dialog/Dialog.service';
import { modalService } from 'afterdoc-design-system/components/Molecules/Modal/Modal.service';
import { overlayPageService } from 'afterdoc-design-system/components/Molecules/OverlayPage/OverlayPage.service';
import { useSetAtom } from 'jotai/index';
import { useNavigate } from 'react-router-dom';

export const useDeprecatedRoutingHandle = () => {
  const navigate = useNavigate();
  // 페이지별로 저장하지 않은 정보가 있는지 확인
  // 페이지 이동 전에 경고 모달을 띄울지 확인
  const setShowModalBeforeRoute = useSetAtom(showModalBeforeRouteState);

  const openElectronPage = (nextPath: string): boolean => {
    switch (nextPath) {
      case ROUTES_PATH.ADMIN_CHAT:
        if (window?.electron === undefined) {
          alert('앱에서 실행해야 하는 기능입니다.');
          return true;
        }
        window?.electron?.ipcRenderer.send('Application.showManagerChatWindow');
        return true;
      case ROUTES_PATH.RESERVATION_MANAGEMENT:
        if (window?.electron === undefined) {
          alert('앱에서 실행해야 하는 기능입니다.');
          return true;
        }
        window?.electron?.ipcRenderer.send('Application.showReservationWindow');
        return true;
      default:
        return false;
    }
  };

  const deprecatedRoutingHandle = (
    key: (typeof ROUTES_PATH)[keyof typeof ROUTES_PATH],
    currentPath: string,
    nextPath: string,
  ) => {
    const showModalIfUnsavedChanges = (key: string, nextPath: string) => {
      setShowModalBeforeRoute((prev) => ({
        ...prev,
        [key]: {
          showModal: true,
          nextPath,
        },
      }));
    };

    const isElectronPage = openElectronPage(nextPath);

    if (isElectronPage) {
      return;
    }

    switch (currentPath) {
      case ROUTES_PATH.CUSTOMER_MANAGEMENT:
        showModalIfUnsavedChanges('CUSTOMER_MANAGEMENT', key);
        break;
      case ROUTES_PATH.HOSPITAL_SETTING:
        showModalIfUnsavedChanges('HOSPITAL_SETTING', key);
        break;
      case ROUTES_PATH.CUSTOMER_CHAT:
        showModalIfUnsavedChanges('CUSTOMER_CHAT', key);
        break;
      case ROUTES_PATH.AUTOMATION:
        showModalIfUnsavedChanges('AUTOMATION', key);
        break;
      case ROUTES_PATH.CONTENT:
        showModalIfUnsavedChanges('CONTENT', key);
        break;
      default:
        console.error('Unhandled case:', currentPath);
        break;
    }

    navigate(key);
    overlayPageService.popAll();
    modalService.popAll();
    dialogService.popAll();
  };

  return {
    deprecatedRoutingHandle,
    openElectronPage,
  };
};
