import { apiClient } from '@apis/instances/api-client';
import { QUERY_KEY } from '@apis/swaggers/query-key';
import type { ApiNoticeServicenoticeElFindParams } from '@apis/swaggers/swagger-docs';
import { useQuery } from '@tanstack/react-query';
import { serviceNoticeListState } from '@templates/ServiceNoticeDialog/states/service-notice-list-state';
import type { ServiceNoticeTabs } from '@templates/ServiceNoticeDialog/types/service-notice-tabs';
import { useSelectedHospitalInfo } from 'afterdoc-saas-web/shared/hooks/info/use-selected-hospital-info';
import { useUserInfo } from 'afterdoc-saas-web/shared/hooks/info/use-user-info';
import { useDataEventBusHandler } from 'hooks/use-data-event-bus-handler/use-data-event-bus-handler';
import { useAtom } from 'jotai/index';
import { isEqual } from 'lodash-es';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { SHARED_UTILS } from 'utils/utils';

const DEFAULT_LIMIT = 20;

const fetchServiceNoticeList = async (params: ApiNoticeServicenoticeElFindParams) => {
  const { data } = await apiClient.v3.apiNoticeServicenoticeElFind(params);
  return SHARED_UTILS.api.checkApiResponse(data);
};

export const useServiceNoticeList = (activeTab?: ServiceNoticeTabs['key']) => {
  const { userId: userID } = useUserInfo();
  const { hospitalID } = useSelectedHospitalInfo();

  const [isAllDataFetched, setIsAllDataFetched] = useState(false);

  const [serviceNoticeList, setServiceNoticeList] = useAtom(serviceNoticeListState);

  const handleNotificationRef = useRef<(() => void) | null>(null);

  const [queryParamKey, setQueryParamKey] = useState<ApiNoticeServicenoticeElFindParams>({
    userID,
    hospitalID,
    skip: 0,
    limit: 20,
  });

  const { data, isLoading, isError } = useQuery({
    queryKey: [QUERY_KEY.apiNoticeServicenoticeElFind, queryParamKey] as const,
    queryFn: ({ queryKey }) => fetchServiceNoticeList(queryKey[1]),
    enabled: 'category' in queryParamKey,
    gcTime: 0,
    staleTime: 0,
  });

  const updateQueryParamKey = useCallback(
    (newParams: Partial<ApiNoticeServicenoticeElFindParams>) => {
      setQueryParamKey((prev) => {
        const updatedParams = { ...prev, ...newParams };
        if (!isEqual(prev, updatedParams)) {
          return updatedParams;
        }
        return prev;
      });
    },
    [],
  );

  const handleScrollToEnd = useCallback(() => {
    if (isLoading || isAllDataFetched) return;

    updateQueryParamKey({
      limit: DEFAULT_LIMIT,
      skip: serviceNoticeList.length,
    });
  }, [isLoading, isAllDataFetched, serviceNoticeList]);

  useEffect(() => {
    if (activeTab) {
      setIsAllDataFetched(false);
      updateQueryParamKey({
        limit: DEFAULT_LIMIT,
        skip: 0,
        category: activeTab === 'All' ? undefined : activeTab,
      });
    }
  }, [activeTab]);

  useEffect(() => {
    if (data !== undefined) {
      setServiceNoticeList((prev) => {
        if (!queryParamKey.skip) {
          return [...data];
        }
        return [...prev, ...data];
      });
      setIsAllDataFetched(data.length < DEFAULT_LIMIT);
    }
  }, [data, queryParamKey]);

  const notificationConfigs = useMemo(
    () => [
      {
        codes: ['d_ServiceNotice_h'],
        handler: () => handleNotificationRef.current?.(),
      },
    ],
    [],
  );

  useDataEventBusHandler(notificationConfigs);

  useEffect(() => {
    handleNotificationRef.current = () => {
      updateQueryParamKey({
        limit: DEFAULT_LIMIT + (queryParamKey.skip ?? 0) + 1,
        skip: 0,
      });
    };
  }, [queryParamKey, activeTab]);

  return {
    serviceNoticeList,
    setServiceNoticeList,
    isLoading,
    isError,
    isAllDataFetched,
    handleScrollToEnd,
  };
};
