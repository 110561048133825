import { useQueryClient } from '@tanstack/react-query';
import { QUERY_KEY } from 'afterdoc-saas-web/apis/swaggers/query-key';
import { shouldResetCustomerManagementTableOnSaveState } from 'afterdoc-saas-web/hooks/push-notification/states/should-reset-customer-management-table-request-on-save';
import { useSelectedHospitalInfo } from 'afterdoc-saas-web/shared/hooks/info/use-selected-hospital-info';
import type { DataNotificationPayload } from 'hooks/use-data-event-bus-handler/event-bus';
import { useDataEventBusHandler } from 'hooks/use-data-event-bus-handler/use-data-event-bus-handler';
import { useSetAtom } from 'jotai';
import { useEffect, useMemo, useRef } from 'react';

export const useResetTableOnSaveCustomerManagementTable = () => {
  const handlePatientsNotificationRef = useRef<((payload: DataNotificationPayload) => void) | null>(
    null,
  );

  const notificationConfigs = useMemo(
    () => [
      {
        codes: ['d_PatientsUpdate_el'],
        handler: (payload: DataNotificationPayload) =>
          handlePatientsNotificationRef.current?.(payload),
      },
    ],
    [],
  );

  const queryClient = useQueryClient();
  const { hospitalID } = useSelectedHospitalInfo();
  const setShouldResetCustomerManagementTableOnSave = useSetAtom(
    shouldResetCustomerManagementTableOnSaveState,
  );

  useDataEventBusHandler(notificationConfigs);

  useEffect(() => {
    handlePatientsNotificationRef.current = async (payload: DataNotificationPayload) => {
      const { hospitalID: messageHospitalID } = payload.data;

      if (!hospitalID) return;

      if (hospitalID === messageHospitalID) {
        await Promise.all([
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEY.apiPatientsElListOrSearch],
          }),
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEY.temporaryPatientsIsLockHandler, { hospitalID }],
          }),
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEY.apiPatientsElCount, { hospitalID }],
          }),
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEY.apiPatientsElUntaggedCount, { hospitalID }],
          }),
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEY.apiPatientsElListOrSearchForUntagged],
          }),
        ]);
        setShouldResetCustomerManagementTableOnSave(true);
      } else {
        setShouldResetCustomerManagementTableOnSave(false);
      }
    };
  }, [hospitalID]);
};
