import type { QueryClient } from '@tanstack/query-core';
import type { AlertProps } from 'afterdoc-design-system/components/Molecules/Alert/Alert';
import { QUERY_KEY } from 'afterdoc-saas-web/apis/swaggers/query-key';
import { ChatActivated } from 'afterdoc-saas-web/assets/icons/gnb';
import type { TableTabValue } from 'afterdoc-saas-web/templates/CustomerManagement/containers/BoardPanel/containers/shared/containers/SearchFilter/states/tabs';
import type { CustomerManagementPanelTitle } from 'afterdoc-saas-web/templates/CustomerManagement/containers/ControlPanel/containers/CustomerManagementPanelList/constants/panel-items';
import type { NavigateFunction } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

export const MESSAGE_PUSH_KEY = {
  m_ChatReceive_el: 'm_ChatReceive_el',
  m_ChatReceive_an: 'm_ChatReceive_an',
  m_ChatReceive_h: 'm_ChatReceive_h',
  m_ChatReceive_m: 'm_ChatReceive_m',
  m_ChatroomUpdateDoctor_el: 'm_ChatroomUpdateDoctor_el',
  m_ServiceNotice_h: 'm_ServiceNotice_h',
} as const;

export interface MessagePushNotificationDataMap {
  [MESSAGE_PUSH_KEY.m_ChatReceive_el]: {
    senderID: string;
    hospitalID: string;
    chatRoomID: string;
    chatType: string;
  };
  [MESSAGE_PUSH_KEY.m_ChatReceive_an]: {
    senderID: string;
    hospitalID: string;
    chatRoomID: string;
    chatType: string;
  };
  [MESSAGE_PUSH_KEY.m_ChatReceive_h]: {
    senderID: string;
    hospitalID: string;
    chatRoomID: string;
    chatType: string;
  };
  [MESSAGE_PUSH_KEY.m_ChatReceive_m]: {
    senderID: string;
    hospitalID: string;
    chatRoomID: string;
    chatType: string;
  };
  [MESSAGE_PUSH_KEY.m_ChatroomUpdateDoctor_el]: {
    senderID: string;
    hospitalID: string;
    chatRoomID: string;
  };
  [MESSAGE_PUSH_KEY.m_ServiceNotice_h]: {
    senderID: string;
    hospitalID: string;
    serviceNoticeID: string;
    category: string;
    subCategory: string;
    data: string;
  };
}

type MessagePushNotificationHandlers = {
  [K in keyof typeof MESSAGE_PUSH_KEY]: (
    data: MessagePushNotificationDataMap[K],
    navigate: NavigateFunction,
    queryClient: QueryClient,
  ) => Partial<AlertProps>;
};

// 공통 handleNavigate 함수 분리
const createNavigateHandler = (navigate?: NavigateFunction) => (href: string) => {
  if (!navigate) return;
  const [basePath, queryString] = href.split('?');
  const searchParams = new URLSearchParams(queryString);
  const newHref = `${basePath}?${searchParams.toString()}`;
  navigate(newHref, { replace: true });
};

const createServiceNoticeHandler = () => {
  return (
    data: MessagePushNotificationDataMap[typeof MESSAGE_PUSH_KEY.m_ServiceNotice_h],
    navigate?: NavigateFunction,
  ): Partial<AlertProps> => {
    const type = MESSAGE_PUSH_KEY.m_ServiceNotice_h;
    const handleNavigate = createNavigateHandler(navigate);
    switch (data.subCategory) {
      case 'PM_JoinChatRoom_Responder': {
        const { chatRoomID } =
          (JSON.parse(data.data) as {
            chatRoomID: string;
            status: string;
            hserviceID: string;
          }) ?? {};

        return {
          id: `${type}_${uuidv4()}`,
          onClickAlert: () => {
            handleNavigate(`/customer-chat?chatRoomId=${chatRoomID}&reload=${Date.now()}`);
          },
        };
      }
      case 'PM_JoinChatRoom_Requestor': {
        const {
          chatRoomID,
          status,
          hserviceID: patientID,
        } = (JSON.parse(data.data) as {
          chatRoomID: string;
          status: string;
          hserviceID: string;
        }) ?? {};

        if (status !== 'approved') {
          return {
            id: `${type}_${uuidv4()}`,
          };
        }

        return {
          id: `${type}_${uuidv4()}`,
          onClickAlert: () => {
            handleNavigate(`/customer-chat?chatRoomId=${chatRoomID}&reload=${Date.now()}`);
          },
        };
      }
      // 치료태그 입력 필요
      case 'TP_NeedPatient_EmptyTag1000': {
        const tabTitle: CustomerManagementPanelTitle = '치료태그 미입력';
        return {
          id: `${type}_${uuidv4()}`,
          onClickAlert: () => {
            handleNavigate(`/customer-management?tabTitle=${tabTitle}`);
          },
        };
      }
      // 고객정보 입력 완료
      case 'TP_NeedPatient_ExistTag1000': {
        const tabTitle: CustomerManagementPanelTitle = '고객정보 입력';
        const tableTabValue: TableTabValue = 'allCustomer';
        return {
          id: `${type}_${uuidv4()}`,
          onClickAlert: () => {
            handleNavigate(`/customer-management?tabTitle=${tabTitle}&tableTab=${tableTabValue}`);
          },
        };
      }
      // 고객정보 입력 필요
      case 'TP_NeedPatient_EmptyData1000':
      case 'TP_NeedPatient_EmptyData1800':
      case 'TP_NeedPatient_EmptyData2000': {
        const tabTitle: CustomerManagementPanelTitle = '고객정보 입력';
        return {
          id: `${type}_${uuidv4()}`,
          onClickAlert: () => {
            handleNavigate(`/customer-management?tabTitle=${tabTitle}`);
          },
        };
      }
      // 예약관련
      case 'RM_CreateV2Reservation':
      case 'RM_UpdateV2Reservation':
      case 'RM_RemoveV2Reservation': {
        const reservationData = JSON.parse(data.data) as {
          reservationID: string;
        };
        const payload =
          reservationData && 'reservationID' in reservationData && reservationData.reservationID
            ? { value: { _id: reservationData.reservationID } }
            : undefined;

        return {
          id: `${type}_${uuidv4()}`,
          onClickAlert: () => {
            window?.electron?.ipcRenderer.send('Application.showReservationWindow', payload);
          },
        };
      }
      // 고객정보 입력 완료
      case 'PM_Completion_PatientsProcessing': {
        const tabTitle: CustomerManagementPanelTitle = '고객정보 입력';
        const tableTabValue: TableTabValue = 'customerInfo';
        return {
          id: `${type}_${uuidv4()}`,
          onClickAlert: () => {
            handleNavigate(`/customer-management?tabTitle=${tabTitle}&tableTab=${tableTabValue}`);
          },
        };
      }
    }

    return {
      id: `${type}_${uuidv4()}`,
    };
  };
};

export const MessagePushNotification: MessagePushNotificationHandlers = {
  [MESSAGE_PUSH_KEY.m_ChatReceive_el]: (data, navigate) => {
    const handleNavigate = createNavigateHandler(navigate);
    return {
      id: `m_ChatReceive_el_${uuidv4()}`,
      onClickAlert: () => {
        handleNavigate(`/customer-chat?chatRoomId=${data.chatRoomID}&reload=${Date.now()}`);
      },
    };
  },

  [MESSAGE_PUSH_KEY.m_ChatReceive_an]: (data, navigate) => {
    const handleNavigate = createNavigateHandler(navigate);
    return {
      id: `m_ChatReceive_an_${uuidv4()}`,
      titleProps: {
        adjacentChildren: {
          position: 'left',
          children: <ChatActivated width={20} height={20} />,
        },
      },
      onClickAlert: () => {
        handleNavigate(`/customer-chat?chatRoomId=${data.chatRoomID}&reload=${Date.now()}`);
      },
    };
  },
  [MESSAGE_PUSH_KEY.m_ChatReceive_h]: (data, navigate) => {
    const handleNavigate = createNavigateHandler(navigate);
    const { chatRoomID } = data as {
      chatRoomID: string;
      patientID: string;
      senderID: string;
      chatType: string;
      hospitalID: string;
    };
    return {
      id: `m_ChatReceive_h_${uuidv4()}`,
      titleProps: {
        adjacentChildren: {
          position: 'left',
          children: <ChatActivated width={20} height={20} />,
        },
      },
      onClickAlert: () => {
        handleNavigate(`/customer-chat?chatRoomId=${chatRoomID}&reload=${Date.now()}`);
      },
    };
  },

  [MESSAGE_PUSH_KEY.m_ChatReceive_m]: (data, navigate) => {
    const handleNavigate = createNavigateHandler(navigate);
    return {
      id: `m_ChatReceive_m_${uuidv4()}`,
      titleProps: {
        adjacentChildren: {
          position: 'left',
          children: <ChatActivated width={20} height={20} />,
        },
      },
      onClickAlert: () => {
        handleNavigate(`/customer-chat?chatRoomId=${data.chatRoomID}&reload=${Date.now()}`);
      },
    };
  },
  [MESSAGE_PUSH_KEY.m_ChatroomUpdateDoctor_el]: (data, _, queryClient) => {
    return {
      id: `m_ChatroomUpdateDoctor_el_${uuidv4()}`,
      onClickAlert: () => {
        queryClient.refetchQueries({
          queryKey: [
            QUERY_KEY.v2apiChatroomElUnreadcount,
            { hospitalID: data.hospitalID, userID: data.senderID, isDoctor: true },
          ],
        });

        window?.electron?.ipcRenderer.send('Application.showManagerChatWindow', {
          value: data,
        });
      },
    };
  },
  [MESSAGE_PUSH_KEY.m_ServiceNotice_h]: createServiceNoticeHandler(),
};
