import { SHARED_UTILS } from '@shared-utils/utils';
import { useMutation, useQuery } from '@tanstack/react-query';
import { QUERY_KEY } from 'afterdoc-saas-web/apis/swaggers/query-key';
import NavigationBar from 'afterdoc-saas-web/shared/components/NavigationBar/NavigationBar';
import ServiceManagerNavigationBar from 'afterdoc-saas-web/shared/components/ServiceManagerNavigationBar/ServiceManagerNavigationBar';
import { LOCAL_USER_ID as userIdFromDev } from 'afterdoc-saas-web/shared/constants/temp-id';
import PageLayout from 'afterdoc-saas-web/shared/routes/PageLayout';
import { isServiceManagerNavigatorVisibleState } from 'afterdoc-saas-web/shared/states/is-service-manager-navigator-visible';
import {
  hospitalInfosAtom,
  selectedHospitalIDAtom,
  selectedNotificationAlarmAtom,
  tokenAtom,
  userIDAtom,
  userInfoAtom,
  userTypeAtom,
} from 'afterdoc-saas-web/states/token';
import 'dayjs/locale/ko';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useEffect, useMemo } from 'react';
import { apiClient } from './apis/instances/api-client';
import type {
  ApiElUpdateDeviceinfoHandlerBodyRequest,
  ApiHospitalsElFindParams,
  ApiUserElFindOneParams,
  ElectronNotificationSoundsHandlerParams,
  ElectronNotificationSoundsHandlerResponse,
  Hospital,
  User,
} from './apis/swaggers/swagger-docs';

interface ExtendedApiHospitalsElFindParams extends ApiHospitalsElFindParams {
  nurseID?: string;
  isEnabledSaaS?: boolean;
}

const fetchHospitalListsInfo = async (params: ExtendedApiHospitalsElFindParams) => {
  try {
    const response = await apiClient.v3.apiHospitalsElFind(params);
    const result = SHARED_UTILS.api.checkApiResponse<Hospital[]>(response.data);
    return result;
  } catch (error) {
    console.error('Error fetching hospital info', error);
    return undefined;
  }
};

const fetchHospitalInfo = async (params: ApiHospitalsElFindParams) => {
  try {
    const response = await apiClient.v3.apiHospitalsElFind(params);
    const result = SHARED_UTILS.api.checkApiResponse(response.data);
    return result[0];
  } catch (error) {
    console.error('Error fetching hospital info', error);
    return undefined;
  }
};

const fetchUserInfo = async (params: ApiUserElFindOneParams) => {
  const response = await apiClient.v2.apiUserElFindOne(params);
  return SHARED_UTILS.api.checkApiResponse(response.data) as User & {
    _id: string;
  };
};

const fetchNotificationSound = async (params: ElectronNotificationSoundsHandlerParams) => {
  const response = await apiClient.v3.electronNotificationSoundsHandler(params);
  return SHARED_UTILS.api.checkApiResponse<ElectronNotificationSoundsHandlerResponse['data']>(
    response.data,
  );
};

const updateDeviceInfo = async (params: ApiElUpdateDeviceinfoHandlerBodyRequest) => {
  const response = await apiClient.v3.apiElUpdateDeviceinfoHandler(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

interface AppProps {
  isElectronEnabled: boolean | null;
}

const App = ({ isElectronEnabled }: AppProps) => {
  const token = useAtomValue(tokenAtom);

  const [isServiceManagerNavigatorVisible, setIsServiceManagerNavigatorVisible] = useAtom(
    isServiceManagerNavigatorVisibleState,
  );
  const setUserInfo = useSetAtom(userInfoAtom);
  const setHospitalInfos = useSetAtom(hospitalInfosAtom);
  const [selectedHospitalID, setSelectedHospitalID] = useAtom(selectedHospitalIDAtom);
  const userID = useAtomValue(userIDAtom);
  const userType = useAtomValue(userTypeAtom);

  const currentAlarmAtom = useMemo(() => selectedNotificationAlarmAtom(userID), [userID]);
  const [notificationAlarm, setNotificationAlarm] = useAtom(currentAlarmAtom);

  // 2000 ~ 2999: 병원 계정
  const isHospitalAccount = userType < 3000 && userType >= 2000;
  // 3000 ~ 3999: 서비스 계정
  const isServiceManagerAccount = userType >= 3000;

  const { data: userInfoFromServer, isFetched } = useQuery({
    queryKey: [
      QUERY_KEY.v2apiUserElFindOne,
      { userID: isElectronEnabled && userID.length > 0 ? userID : userIdFromDev },
    ] as const,
    enabled: (isElectronEnabled && userID.length > 0) || (!isElectronEnabled && !!token.length),
    queryFn: ({ queryKey }) => fetchUserInfo(queryKey[1]),
  });

  const { data: hospitalListsInfo } = useQuery({
    queryKey: [
      QUERY_KEY.apiHospitalsElFind,
      {
        nurseID: userID,
        isEnabledSaaS: true,
      },
    ] as const,
    enabled:
      (isServiceManagerAccount && userID.length > 0) || (!isElectronEnabled && !!token.length),
    queryFn: ({ queryKey }) => fetchHospitalListsInfo(queryKey[1]),
  });

  const { data: forDevHospitalListsInfo } = useQuery({
    queryKey: [
      `${QUERY_KEY.apiHospitalsElFind}-forDev`,
      {
        nurseID: userIdFromDev,
        isEnabledSaaS: true,
      },
    ] as const,
    enabled: isElectronEnabled !== null && isElectronEnabled === false && !!token.length,
    queryFn: ({ queryKey }) => fetchHospitalListsInfo(queryKey[1]),
  });

  const { data: hospitalInfo } = useQuery({
    queryKey: [
      QUERY_KEY.apiHospitalsElFind,
      {
        _id: selectedHospitalID,
      },
    ] as const,
    enabled: isHospitalAccount && !!selectedHospitalID && !!token.length,
    queryFn: ({ queryKey }) => fetchHospitalInfo(queryKey[1]),
  });

  const { data: soundList } = useQuery({
    queryKey: [
      QUERY_KEY.electronNotificationSoundsHandler,
      { hospitalID: selectedHospitalID },
    ] as const,
    enabled: !notificationAlarm.soundID && !!selectedHospitalID && !!token.length,
    queryFn: ({ queryKey }) => fetchNotificationSound(queryKey[1]),
  });

  const updateDeviceInfoMutation = useMutation({
    mutationFn: (params: ApiElUpdateDeviceinfoHandlerBodyRequest) => updateDeviceInfo(params),
  });

  useEffect(() => {
    if (window?.electron === undefined) {
      if (forDevHospitalListsInfo) {
        setHospitalInfos(forDevHospitalListsInfo);
      }
    }
  }, [forDevHospitalListsInfo]);

  useEffect(() => {
    if (userInfoFromServer && isFetched) {
      setUserInfo(userInfoFromServer);
    }
  }, [userInfoFromServer, isFetched]);

  useEffect(() => {
    if (hospitalListsInfo?.length && isElectronEnabled && isServiceManagerAccount) {
      setHospitalInfos(hospitalListsInfo);
      setIsServiceManagerNavigatorVisible(true);
      const hospitalIds = hospitalListsInfo.map((hospital) => hospital._id);
      window.electron?.ipcRenderer.send('Application.Set.v2HospitalIDs', {
        value: hospitalIds,
      });

      const hospitalID = hospitalListsInfo[0]._id as string;
      setSelectedHospitalID(hospitalID);
      window.electron?.ipcRenderer.send('Application.Set.hospitalID', hospitalID);
    }
  }, [hospitalListsInfo, isElectronEnabled]);

  useEffect(() => {
    if (hospitalInfo?._id && isElectronEnabled && isHospitalAccount) {
      setHospitalInfos([hospitalInfo]);
      setIsServiceManagerNavigatorVisible(false);
      window.electron?.ipcRenderer.send('Application.Set.hospitalID', hospitalInfo._id);
    }
  }, [hospitalInfo, isElectronEnabled]);

  useEffect(() => {
    if (!window?.electron) return;

    window.electron?.ipcRenderer.send('Application.Get.userInfo');

    return () => {
      window.electron?.ipcRenderer.removeAllListeners('Application.Get.userInfo');
    };
  }, []);

  useEffect(() => {
    if (!window?.electron) return;

    const handleDeviceInfoUpdate = (
      _: Record<string, never>,
      payload?: Payload<ApiElUpdateDeviceinfoHandlerBodyRequest>,
    ) => {
      if (payload && typeof payload === 'object' && payload.value) {
        updateDeviceInfoMutation.mutate(
          { ...payload.value },
          {
            onError: (error) => {
              console.error('Error occurred while updating device info:', error);
            },
          },
        );
      }
    };

    window.electron?.ipcRenderer.on('Application.Get.fcmToken', handleDeviceInfoUpdate);

    window.electron?.ipcRenderer.send('Application.Get.fcmToken');

    return () => {
      window.electron?.ipcRenderer.removeAllListeners('Application.Get.fcmToken');
    };
  }, []);

  useEffect(() => {
    if (soundList && notificationAlarm) {
      const defaultSound = soundList.find((item) => item.isDefault);
      if (defaultSound) {
        setNotificationAlarm({
          usingSound: true,
          soundID: defaultSound._id,
          path: defaultSound.path,
          volume: 0.6,
        });
      }
    }
  }, [soundList, setNotificationAlarm]);

  return (
    <>
      <NavigationBar />
      {isServiceManagerNavigatorVisible && <ServiceManagerNavigationBar />}
      <PageLayout />
    </>
  );
};

export default App;
