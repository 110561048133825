import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import { ROUTES_PATH } from 'afterdoc-saas-web/shared/components/NavigationBar/constants/menu-items';
import { isServiceManagerNavigatorVisibleState } from 'afterdoc-saas-web/shared/states/is-service-manager-navigator-visible';
import { useAtomValue } from 'jotai';
import { useMemo } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

export default function PageLayout() {
  const location = useLocation();
  const isServiceManagerNavigatorVisible = useAtomValue(isServiceManagerNavigatorVisibleState);

  const isHidden = useMemo(
    () => location.pathname === ROUTES_PATH.INSTAGRAM_LINK_RESULT,
    [location.pathname],
  );

  const pageLeftPadding = isHidden
    ? null
    : isServiceManagerNavigatorVisible
      ? 'pl-[140px]'
      : 'pl-70';

  return (
    <div className={customTwMerge('flex h-full w-full', `${pageLeftPadding}`)}>
      <Outlet />
    </div>
  );
}
