import type { ServiceNotice } from '@apis/swaggers/swagger-docs';
import { formatCardCreatedDate } from '@templates/ServiceNoticeDialog/functions/format-card-created-date';
import Badge from 'afterdoc-design-system/components/Atoms/Badge/Badge';
import { Fragment } from 'react';

interface ReservationCanceledCardProps {
  data: ServiceNotice;
}

export default function ReservationCanceledCard({ data }: ReservationCanceledCardProps) {
  return (
    <>
      <div className='flex cursor-pointer flex-col gap-10 p-20'>
        <Badge isBadge={!data.isRead} wrapperClassName={'flex-grow'}>
          <span className='flex min-h-[24px] w-full items-center text-Header14 text-red500'>
            {data.title}
          </span>
        </Badge>
        <span className={'break-all text-Body12 text-black500'}>{data.body}</span>
        <div className='rounded-r10 border border-white400 bg-white100 px-16 py-12 text-Body12 text-black500'>
          <div className='flex w-full gap-10'>
            <span className='h-[20px] w-33 flex-center flex-shrink-0 rounded-r10 bg-red50 text-Body10 text-align text-red500'>
              취소
            </span>
            <div className='flex-grow break-all'>
              {data.content?.split('\n').map((line, index) => (
                <Fragment key={index}>
                  {line}
                  <br />
                </Fragment>
              ))}
            </div>
          </div>
        </div>
        <span className='flex justify-end text-Body10 text-black200'>
          {formatCardCreatedDate(data.createdAt)}
        </span>
      </div>
    </>
  );
}
