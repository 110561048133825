import { isUpdatingState } from '@templates/ServiceNoticeDialog/states/is-updating-state';
import { serviceNoticeListState } from '@templates/ServiceNoticeDialog/states/service-notice-list-state';
import type { ServiceNoticeTabs } from '@templates/ServiceNoticeDialog/types/service-notice-tabs';
import ServiceNoticeDialogHeader from 'afterdoc-saas-web/templates/ServiceNoticeDialog/components/ServiceNoticeDialogHeader';
import ServiceNoticeDialogTabs from 'afterdoc-saas-web/templates/ServiceNoticeDialog/components/ServiceNoticeDialogTabs';
import ServiceNoticeList from 'afterdoc-saas-web/templates/ServiceNoticeDialog/components/ServiceNoticeList/ServiceNoticeList';
import UnreadNotificationSection from 'afterdoc-saas-web/templates/ServiceNoticeDialog/components/UnreadNotificationSection';
import { useClickOutside } from 'hooks/use-click-outside';
import { useResetAtom } from 'jotai/utils';
import { useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

interface ServiceNoticeDialogProps {
  onClose: () => void;
  handleNavigate: (href: string) => void;
}

export default function ServiceNoticeDialog({ onClose, handleNavigate }: ServiceNoticeDialogProps) {
  const [activeTab, setActiveTab] = useState<ServiceNoticeTabs['key']>('All');

  const resetIsUpdating = useResetAtom(isUpdatingState);
  const resetServiceNoticeList = useResetAtom(serviceNoticeListState);

  const divRef = useRef<HTMLDivElement>(null);

  useClickOutside({
    id: uuidv4(),
    ref: divRef,
    contentRef: divRef,
    onClose: () => onClose(),
  });

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') onClose();
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    return () => {
      resetIsUpdating();
      resetServiceNoticeList();
    };
  }, []);

  return (
    <div
      className='h-[80%] min-h-[200px] w-[360px] overflow-hidden rounded-r16 bg-white50 shadow-inbox'
      ref={divRef}>
      <div className='flex h-full flex-col'>
        <ServiceNoticeDialogHeader onClose={onClose} />
        <ServiceNoticeDialogTabs activeTab={activeTab} setActiveTab={setActiveTab} />
        <UnreadNotificationSection activeTab={activeTab} />
        <ServiceNoticeList activeTab={activeTab} handleNavigate={handleNavigate} />
      </div>
    </div>
  );
}
