import { apiClient } from '@apis/instances/api-client';
import type { ApiChatroomElFindOneParams } from '@apis/swaggers/swagger-docs';
import { useMutation } from '@tanstack/react-query';
import { toastService } from 'afterdoc-design-system/components/Atoms/Toast/Toast.service';
import { useUserInfo } from 'afterdoc-saas-web/shared/hooks/info/use-user-info';
import { SHARED_UTILS } from 'utils/utils';

const fetchChatRoom = async (param: ApiChatroomElFindOneParams) => {
  const { data } = await apiClient.v3.apiChatroomElFindOne(param);
  return SHARED_UTILS.api.checkApiResponse(data);
};

export const useHasAccessibleChatRoom = () => {
  const { userId } = useUserInfo();

  const fetchChatRoomMutation = useMutation({
    mutationFn: (param: ApiChatroomElFindOneParams) => fetchChatRoom(param),
  });

  const checkChatRoom = (chatRoomID: string | undefined) => {
    return new Promise((resolve) => {
      if (!chatRoomID) {
        showErrorToast('ERROR');
        return resolve(false);
      }

      fetchChatRoomMutation.mutate(
        { chatRoomID },
        {
          onSuccess: (data) => {
            if (data.users?.some((user) => user._id === userId)) {
              resolve(true);
            } else {
              showErrorToast('NO_ACCESSIBLE_CHATROOM');
              resolve(false);
            }
          },
          onError: () => {
            showErrorToast('ERROR');
            resolve(false);
          },
        },
      );
    });
  };

  const showErrorToast = (code: 'NO_ACCESSIBLE_CHATROOM' | 'ERROR') => {
    const message =
      code === 'NO_ACCESSIBLE_CHATROOM'
        ? '채팅방을 조회할 수 없습니다.'
        : '일시적인 오류가 발생했습니다. 다시 시도해 주세요.';
    toastService.errorMsg({ text: message });
  };

  return {
    checkChatRoom,
  };
};
